// src/SocialMediaPage.js
import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { FacebookEmbed } from 'react-social-media-embed';
import { InstagramEmbed } from 'react-social-media-embed';

const socialMediaLinks = [
  { 
    name: 'Facebook', 
    url: 'https://www.facebook.com/photo?fbid=122128321994244022&set=a.122121759788244022', 
    icon: FaFacebook, 
    followers: '8.6k', 
    likes: '1k' 
  },
  { 
    name: 'Instagram', 
    url: 'https://www.instagram.com/p/C6phQietr0t/', 
    icon: FaInstagram, 
    followers: '19.8k', 
  },
];

const SocialMediaPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div className="grid max-w-6xl grid-cols-1 gap-12 mx-auto md:grid-cols-2">
        {socialMediaLinks.map((link, index) => {
          const Icon = link.icon;
          return (
            <div key={index} className="flex flex-col items-center w-full p-8 mx-auto bg-white rounded-lg shadow-lg md:w-full">
              <div className="flex items-center mb-4 text-xl font-semibold text-center">
                <Icon className="mr-2 text-3xl" />
                {link.name}
              </div>
              <div className="flex justify-between w-full mb-4">
                <div className="flex flex-col items-center">
                  <span className="font-semibold">Followers</span>
                  <span>{link.followers}</span>
                </div>
                <div className="flex flex-col items-center">
                  
                  {
                    link.likes && (
                      <>
                      <span className="font-semibold">Likes</span>
                      <span>{link.likes}</span>
                      </>
                    )
                  }
                </div>
              </div>
              <div className="w-full h-full overflow-hidden " 
              style={{
                maxHeight: '515px',
              }}>
                {
                  link.name === 'Facebook' ? (
                    <div className="w-full overflow-hidden">
                      <iframe
                        title="Facebook Post"
                        src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FHealthTeaBoba.USA%2Fposts%2Fpfbid0c6PU4T9pCWfTHhJNn6moGVnXD3sFfZ1GfX5MG2QSAX5ZD5wgYRZ7V3ExAtxLGXSel&show_text=true&width=500&preview=comet_preview"
                        width="100%"
                        height="600"
                        style={{ border: 'none', overflow: 'hidden' }}
                        scrolling="no"
                        frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      ></iframe>
                    </div>
                  ) : (
                    <InstagramEmbed
                      url={link.url}
                      width="100%"
                      height="600px"
                    />
                  )
                }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SocialMediaPage;
