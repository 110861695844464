// src/labels.js
const labels = [
  {
    productName: 'Classic Milk Tea',
    images: [
      {
        name: 'Front Label',
        url: '/test1.jpg',
        alt: 'Classic Milk Tea Front Label Image'
      },
      {
        name: 'Back Label',
        url: 'test2.jpg',
        alt: 'Classic Milk Tea Back Label Image'
      }
    ]
  },
  {
    productName: 'Boba',
    images: [
      {
        name: 'Front Label',
        url: 'https://example.com/path-to-your-boba-front-label.jpg',
        alt: 'Boba Front Label Image'
      },
      {
        name: 'Back Label',
        url: 'https://example.com/path-to-your-boba-back-label.jpg',
        alt: 'Boba Back Label Image'
      }
    ]
  },
  // Add more products as needed
];

export default labels;
