// src/ProductCarouselPage.js
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import SwiperCore, { Navigation, Pagination } from "swiper";
import { Navigation, Pagination } from "swiper/modules";
// install Swiper modules

const products = [
  { id: 1, imageUrl: "/new-test.jpg", name: "Product 1" },
  { id: 2, imageUrl: "/path/to/product2.png", name: "Product 2" },
  { id: 3, imageUrl: "/path/to/product3.png", name: "Product 3" },
];

const ProductCarouselPage = () => {
  return (
    <div className="container py-8 mx-auto">
      <h1 className="mb-4 text-2xl font-bold text-center">
        Available Products
      </h1>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={true}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination]}
        className="h-96"
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="flex justify-center">
              <img
                src={product.imageUrl}
                alt={product.name}
                className="object-contain w-64 h-64 floating"
                style={{ animation: "float 6s ease-in-out infinite" }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductCarouselPage;
