// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import VideoPage from "./video-links";
import SocialMediaPage from "./socialmedia";
import LabelImagesPage from "./LabelImagesPage";
import DigitalLabels from "./DigitalLabelsPage";
import BobaPearlsInstructions from "./boba-instructions-links";
import ProductCarouselPage from "./ProductCarouselPage"
const App = () => {
  return (
    <Router>
      <nav className="w-full px-8 bg-gray-200 shadow shadow-gray-300">
        <div className="container flex items-center justify-center h-16 mx-auto">
          <ul className="flex space-x-8 font-semibold text-gray-500">
            <li>
              <NavLink
                to="/drinks-instruction"
                className={({ isActive }) =>
                  isActive ? "text-indigo-500" : "hover:text-indigo-400"
                }
              >
                Drinks Instruction
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/boba-pearls-instruction"
                className={({ isActive }) =>
                  isActive ? "text-indigo-500" : "hover:text-indigo-400"
                }
              >
                Boba Pearls Instruction
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/social-media-links"
                className={({ isActive }) =>
                  isActive ? "text-indigo-500" : "hover:text-indigo-400"
                }
              >
                Social Media Links
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/digital-labels"
                className={({ isActive }) =>
                  isActive ? "text-indigo-500" : "hover:text-indigo-400"
                }
              >
                Digital Labels
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/available-products"
                className={({ isActive }) =>
                  isActive ? "text-indigo-500" : "hover:text-indigo-400"
                }
              >
                Available Products
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>

      <Routes>
        <Route path="/drinks-instruction" element={<VideoPage />} />
        <Route path="/social-media-links" element={<SocialMediaPage />} />
        <Route path="/digital-labels" element={<DigitalLabels />} />
        <Route path="/available-products" element={<ProductCarouselPage />} />
        <Route
          path="/boba-pearls-instruction"
          element={<BobaPearlsInstructions />}
        />
      </Routes>
    </Router>
  );
};

export default App;
