import React, { useState } from "react";

const videos = [
  {
    title: "Video Title 1",
    url: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    instructions: [
      "Add 2.5 oz (75ml) of water into the blender.",
      "Add 1 scoop of mix (35g or your chosen powder amount).",
      "Add 7 oz (210ml or 90g) of ice.",
      "Blend until smooth.",
    ],
  },
  {
    title: "Video Title 2",
    url: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    instructions: [
      "Add 1 scoop of mix (35g or your chosen powder amount) to 4 fl oz (120ml) of hot water.",
      "Stir until fully dissolved.",
      "Pour the mixture into an 8 oz (240ml) cup filled with ice.",
    ],
  },
  {
    title: "Video Title 3",
    url: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    instructions: [
      "Add 1 scoop of mix (35g or your chosen powder amount).",
      "Add 7 fl oz (210ml) of hot water or brewed hot black tea.",
      "Stir well until completely dissolved.",
      "Add 1 fl oz (30ml) of normal-temperature water.",
      "Stir well.",
    ],
  },
  {
    title: "Video Title 4",
    url: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    instructions: [
      "Step 1 for Video 4",
      "Step 2 for Video 4",
      "Step 3 for Video 4",
    ],
  },
];

const App = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});

  const openModal = (video) => {
    setCurrentVideo(video);
    setCheckedItems({});
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentVideo(null);
  };

  const handleCheckboxChange = (index) => {
    setCheckedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="flex items-center justify-center min-h-screen p-4 bg-gray-200">
      <div className="grid max-w-6xl grid-cols-1 gap-12 mx-auto md:grid-cols-2">
        {videos.map((video, index) => (
          <div key={index} className="p-8 bg-white rounded-lg shadow-lg">
            <div className="mb-4 text-xl font-semibold text-center">
              {video.title}
            </div>
            <div className="mb-4 aspect-w-16 aspect-h-9">
              <iframe
                className="w-full h-full rounded-md"
                src={video.url}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <button
              onClick={() => openModal(video)}
              className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-700"
            >
              Read Text Instructions
            </button>
          </div>
        ))}
      </div>

      {modalOpen && currentVideo && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-2xl p-8 bg-white rounded-lg shadow-lg">
            <button
              onClick={closeModal}
              className="absolute text-gray-500 top-2 right-2 hover:text-gray-700"
            >
              &times;
            </button>
            <div className="mb-4 text-xl font-semibold text-center">
              {currentVideo.title}
            </div>
            <div className="mb-4">
              <iframe
                className="w-full h-64 rounded-md"
                src={currentVideo.url}
                title={currentVideo.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="p-2 m-6 text-center text-white bg-blue-500 rounded-lg shadow-md">
              Multiply the amount by 2 for 16oz (480ml) drinks
            </div>
            <div className="pl-5 text-gray-700">
              {currentVideo.instructions.map((instruction, index) => (
                <div key={index} className="flex items-start mb-2">
                  <input
                    type="checkbox"
                    checked={!!checkedItems[index]}
                    onChange={() => handleCheckboxChange(index)}
                    className="mt-1 mr-2"
                  />
                  <span>
                    {index + 1}. {instruction}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
