// src/DigitalLabelsPage.js
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import labels from "./labels";

const labelMappings = {
  "classic-milk-tea": "Classic Milk Tea",
  boba: "Boba",
};

const Modal = ({ isOpen, onClose, image }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
      onClick={onClose}
    >
      <div
        className="relative p-4 bg-white rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-0 right-0 mt-2 mr-2 text-3xl text-gray-500 hover:text-gray-700" // Increase size of "X" button
        >
          &times;
        </button>
        <img
          src={image.url}
          alt={image.alt}
          className="max-w-full max-h-screen"
        />
      </div>
    </div>
  );
};
const DigitalLabelsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const location = useLocation();
  const highlightedRef = useRef(null);

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const productParam = params.get("product");
    const productName = labelMappings[productParam];
    if (productName) {
      const element = document.getElementById(
        productName.replace(/\s+/g, "-").toLowerCase()
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        highlightedRef.current = element;
        element.classList.add("highlight");
      }
    }
    return () => {
      if (highlightedRef.current) {
        highlightedRef.current.classList.remove("highlight");
      }
    };
  }, [location]);

  return (
    <>
      <div className="flex items-center justify-center min-h-screen px-4 bg-gray-200 md:px-0">
        <div className="grid max-w-6xl grid-cols-1 gap-12 mx-auto md:grid-cols-2">
          {labels.map((label, index) => (
            <div
              key={index}
              id={label.productName.replace(/\s+/g, "-").toLowerCase()}
              className="p-8 bg-white rounded-lg shadow-lg"
            >
              <div className="mb-4 text-xl font-semibold text-center">
                {label.productName}
              </div>
              <div className="grid grid-cols-2 gap-4">
                {label.images.map((image, i) => (
                  <div
                    key={i}
                    className="relative flex flex-col items-center group"
                  >
                    <div className="mb-2 text-lg font-semibold">
                      {image.name}
                    </div>
                    <img
                      src={image.url}
                      alt={image.alt}
                      className="w-full h-auto transition-transform transform rounded-md cursor-pointer group-hover:scale-105"
                      onClick={() => handleImageClick(image)}
                    />
                    <div className="absolute bottom-0 left-0 right-0 p-2 text-center text-white transition-opacity bg-black bg-opacity-50 opacity-0 group-hover:opacity-100">
                      Click for full image
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        image={currentImage}
      />
    </>
  );
};

export default DigitalLabelsPage;
